@import 'variables';
@import 'mixin';

.product {

    &:hover, &:focus-within {        
        .product__actions_footer {
            background: $product-background-footer-active;
            border-radius: $border-radius-link-footer-active !important;
            
            * {
                color: $product-link-footer-active !important;                

                svg {
                    * {
                        stroke: $product-link-footer-active !important;
                    }
               }
            };  
        }        
    }
}

.product__image_container {
    position: relative;

   .product__image {
        figure {
            padding-top: 91.60% !important;
        }
   } 
}

.favorite__button {
    position: absolute;
    right: 16px;
    bottom: 13px;
    z-index: 2;
    flex-direction: column;
    width: fit-content;
    row-gap: 16px;
}

.product__body {
    row-gap: 14px;
    padding: 14px 20px !important;

    @media screen and (max-width: 420px) {
       row-gap: 12px;
       padding: 12px !important;
    }
}

.info__brand_category {
    padding: 0 !important;
}

.product__price_container {
  > div {
    flex-direction: column-reverse !important;
  }
}

.product__actions_footer {
   order: 3;
   width: 100%; 
   min-height: 48px;
   padding: 0 12px;
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   justify-content: center;
   background: $product-background-footer;
   border-radius: $border-radius-link-footer;

   &_disabled {
    cursor: not-allowed;

    > span {
        color: $product-link-footer;
        text-transform: uppercase;
        
        @media screen and (min-width: 420px) {            
            font-size: $text-base;            
        }
    }
   }
}

.actions__counter { 
    height: 24px; 
    background-color: inherit !important;

    .actions__counter_form {
        background-color: inherit !important;
        border: 0 !important;

        button, div {
            padding: 4px 8px;
            height: 24px !important;
            background-color: inherit;
            color: $product-link-footer !important;
            border: 0 !important;
        }

        button {
            svg {
                * {
                    stroke: $product-link-footer !important;
                }
            }

            &:hover { 
                color: $product-link-footer-active !important;

                svg {
                    * {
                        stroke: $product-link-footer-active !important;
                    }
                }
            }
        }
    }

    > button {
        height: 24px;
        position: relative;
        display: grid !important;
        padding: 0;
        background-color: inherit !important;
        color: $product-link-footer !important;
        width: -webkit-fill-available !important;

        svg {
            * {
                stroke:  $product-link-footer !important;
            }
        }

        &:not(:disabled):hover,
        &:not(:disabled):focus {
          border: 0;
          color: $product-link-footer-active !important;
          background: none;

            svg {
                * {
                    stroke:  $product-link-footer-active !important;
                }
            }
        }

        > div {
            display: inline-flex !important;
            width: -webkit-fill-available !important;

            span {
                text-transform: uppercase;
            }
        }

       @media screen and (min-width: 420px) {
            figure {
                svg {
                    width: 18px !important;
                    height: 18px !important;
                }
            }
        }
    }
}