@import 'variables';
@import 'mixin';


.product {
        &:hover {         
            .actions__counter_hover { 
                @include media(lg){  
                    display: flex !important;
                    justify-content: center; 
                }   
            }        
        }
}

.actions__counter_hover {
    position: absolute;
    display: none !important;
    height: 46px;
    margin: 0 20px;
    z-index: 1;
    bottom: 0;
    right: 0;
    left: 0;  

    > div {
        margin: 0 auto;
    }
    
    > button {
        height: 46px;
        border-radius: calc($border-radius-btn - 2px);
        width: -webkit-fill-available !important;     
        
        > div {
            display: inline-flex !important;
        }
    }
}

.product__image_container {
    position: relative;

    .product__image {
        figure {
          padding-top: 83.97% !important;
        }
    }
}

.product__badges_container {
    top: 1rem;
    right: 1rem;
}

.variation__info_carousel {
    padding: 8px 0 !important;

   .variation__info_badge {
        font-size: $text-sm;
        line-height: 16px;
        min-width: fit-content;
        padding: 4px 8px;
        height: 24px;
        letter-spacing: 0.03px;
        border-radius: $product-info-border-radius;

        &_border_less {
            border: 0 !important;
        }
    }
   
}

.actions__body {
    position: absolute;
    left: 16px;
    top: 11.5px;
    z-index: 2;
    flex-direction: column;
    width: fit-content;
    row-gap: 16px;
}

.product__actions_footer {
    @include media(lg){
      display: none;
    }  
}

.actions__counter {
    margin-top: 12px;  
    height: 46px;  

    > button {
      height: 46px;
      border-radius: calc($border-radius-btn - 2px);
      width: -webkit-fill-available !important;

      > div {
        display: inline-flex !important;
      }
    }

    > div {
        margin: 0 auto;
    }
}