@import 'variables';
@import 'mixin';


.action__body_mobile {
    @media screen and (max-width: 640px) {
       position: absolute;
       top: 16px;
       left: 16px;
       flex-direction: column !important;
       z-index: 1;

       > div {
         display: none;
       }
    }
}

.actions__counter_mobile {
    @media screen and (max-width: 640px) {
       margin: 0 auto;
    }
}

