@import 'variables';
@import 'mixin';


.variation__info_carousel {
    margin: 0 0 12px !important;

   .variation__info_badge {
        font-size: $text-sm;
        line-height: 16px;
        min-width: fit-content;
        padding: 4px 8px;
        height: 24px;
        letter-spacing: 0.03px;
        border-radius: $product-info-border-radius;

        &_border_less {
            border: 0 !important;
        }
    }
   
}

.product__image_container {
    figure {
        padding-top: 91.60% !important;
    }

}

.product_badge__container{
    right: 0;
    left: 12px;
    align-items: flex-start;

    > span {
        margin-left: unset !important;
    }
}

.favorite__button {
    position: absolute;
    right: 16px;
    top: 12px;
    z-index: 2;
    flex-direction: column;
    width: fit-content;
    row-gap: 16px;
}

.product__actions_footer {
   margin-top: 16px;
   display: flex;
   align-items: center;
   flex-wrap: nowrap;
   column-gap: 12px;
   justify-content: space-between;
   width: 100%;   

    @media screen and (max-width: 420px) {
        flex-direction: column;
        row-gap: 16px;

        .product__price_container {
            width: 100%;
        }
    }
}

.actions__counter { 
    width: 100% !important;

    @media screen and (min-width: 420px) {
        width: fit-content !important;
        display: flex;
    }

    > button {
       position: relative;
       margin-left: auto !important;
       height: 100%;
       height: 46px !important;
       padding: 8px !important;
       border-radius: $border-radius-link-footer;

        @media screen and (min-width: 420px) {
            width: 46px !important;
            margin-left: auto !important;

            span {
                display: none !important;
            }

            figure {
                margin-right: 0 !important;
            }
        }

        @media screen and (min-width: 1024px){
          padding: 8px !important;
        }

        figure {
            svg {
                width: 22px !important;
                height: 22px !important;
            }
        }

       > div {
            display: inline-flex !important;
       }
    }

    > div {
       height: 46px !important;
        margin: 0 auto;
    }
}