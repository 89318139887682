@import '../../styles/variables';
@import '../../styles/mixin';

@keyframes sheen {
  0% {
    left: -80px;
  }

  13% {
    opacity: 0.2;
  }

  25% {
    left: 100%;
    opacity: 0;
  }
}

.promotion {
  background: $background-promotion;
  background-image: $background-promotion-image;
  background-position: 50%;
  background-repeat: no-repeat !important; 
  background-size: cover !important;
  color: $color-text-promotion;
  overflow: hidden;
  font-size: $text-sm !important;
  font-weight: 500;
  text-transform: $text-transform-promotions;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 10;

  &::after {
    animation: sheen 17s ease-in-out infinite normal none running;
    background: $efect-promotion;
    content: '';
    display: inline-block;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 50px;
    height: 100%;
    opacity: 0;
    transform: skewX(45deg);
    z-index: 2;
  }

  .promotion__text {
    min-height: 40px;
    flex-grow: 1;

    &.alert {
      //align-items: center;
     // align-content: center;
      text-align: center;
      font-weight: 500;
      font-size: $text-sm !important;
      background: transparent;
      color: inherit;
      margin-bottom: 0;
      padding: 10px 0;
      border: 0 !important;
    }

   /*  button {
      margin: 0 8px !important;
    } */
  }

  svg {
    font-weight: 700;
    * {
        stroke: $color-text-promotion !important;
        stroke-width:2.5 !important;
      }
  }

  &_swiper {
    width: 80vw;
    height: 100%;

    @include media(sm) {
      width: 85vw;
    }

    @include media(md) {
      width: 92vw;
    }

    &__item {
      -webkit-user-drag: none;
      height: 100%;
      background: $background-promotion;
    }
  }

  a {
    color: $color-text-promotion !important;

    &:hover {
      color: $color-text-promotion !important;
    }
  }
}
