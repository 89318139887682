@import '../../styles/variables';
@import '../../styles/mixin';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 34px;

  .content {
    align-items: center;
    display: grid;
    grid-row-gap: 16px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-items: center;
    width: 100%;

    @media screen and (min-width: 540px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include media(sm) {
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      grid-row-gap: 24px;
    }
    
    @include media(md) {
      grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
    }

    &__large {        
      @include media(sm) {
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        grid-row-gap: 24px;
      }

      @include media(lg) {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      }

      @include media(2xl) {
        grid-row-gap: 36px;
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      }

      @include media(3xl) {
        grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
      }    
    }
  }
}
